"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
var jsx_runtime_1 = require("react/jsx-runtime");
var common_1 = require("./common");
var types_1 = require("../../sources/types");
var icon = ((0, jsx_runtime_1.jsxs)("svg", __assign({ xmlns: "http://www.w3.org/2000/svg", height: "100%", width: "100%", viewBox: "0 0 48 48" }, { children: [(0, jsx_runtime_1.jsxs)("linearGradient", __assign({ id: "Ld6sqrtcxMyckEl6xeDdMa", x1: "9.993", x2: "40.615", y1: "9.993", y2: "40.615", gradientUnits: "userSpaceOnUse" }, { children: [(0, jsx_runtime_1.jsx)("stop", { offset: "0", stopColor: "#2aa4f4" }), (0, jsx_runtime_1.jsx)("stop", { offset: "1", stopColor: "#007ad9" })] })), (0, jsx_runtime_1.jsx)("path", { fill: "url(#Ld6sqrtcxMyckEl6xeDdMa)", d: "M24,4C12.954,4,4,12.954,4,24s8.954,20,20,20s20-8.954,20-20S35.046,4,24,4z" }), (0, jsx_runtime_1.jsx)("path", { fill: "#fff", d: "M26.707,29.301h5.176l0.813-5.258h-5.989v-2.874c0-2.184,0.714-4.121,2.757-4.121h3.283V12.46 c-0.577-0.078-1.797-0.248-4.102-0.248c-4.814,0-7.636,2.542-7.636,8.334v3.498H16.06v5.258h4.948v14.452 C21.988,43.9,22.981,44,24,44c0.921,0,1.82-0.084,2.707-0.204V29.301z" })] })));
var facebookDestination = {
    description: ((0, jsx_runtime_1.jsx)(jsx_runtime_1.Fragment, { children: "Jitsu can send events from JS SDK or Events API to Facebook Marketing API. The common use-case is to send conversions or/and page views to Facebook to use that data for targeting and ad optimization" })),
    syncFromSourcesStatus: "not_supported",
    id: "facebook",
    type: "other",
    displayName: "Facebook",
    defaultTransform: "// Code of Facebook transform:\n// https://github.com/jitsucom/jitsu/blob/master/server/storages/transform/facebook.js\nreturn toFacebook($)",
    hidden: false,
    deprecated: false,
    ui: {
        icon: icon,
        title: function (cfg) { return "Pixel ID: ".concat(cfg._formData.fbPixelId); },
        connectCmd: function (_) { return null; },
    },
    parameters: [
        (0, common_1.modeParameter)("stream"),
        (0, common_1.tableName)(common_1.filteringExpressionDocumentation),
        {
            id: "_formData.fbPixelId",
            displayName: "Pixel ID",
            required: true,
            type: types_1.stringType,
            documentation: ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: ["Your Facebook Pixel ID or", " ", (0, jsx_runtime_1.jsx)("a", __assign({ target: "_blank", rel: "noopener noreferrer", href: "https://www.facebook.com/ads/manager/pixel/facebook_pixel/" }, { children: "create a new one" })), ".", (0, jsx_runtime_1.jsx)("br", {}), "Read more about", " ", (0, jsx_runtime_1.jsx)("a", __assign({ target: "_blank", rel: "noopener noreferrer", href: "https://developers.facebook.com/docs/marketing-api/conversions-api/get-started#-------" }, { children: "Facebook conversion API" }))] })),
        },
        {
            id: "_formData.fbAccessToken",
            displayName: "Access Token",
            required: true,
            type: types_1.stringType,
            documentation: ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: ["Your Facebook Access Token.", (0, jsx_runtime_1.jsx)("br", {}), (0, jsx_runtime_1.jsx)("a", __assign({ target: "_blank", rel: "noopener noreferrer", href: "https://developers.facebook.com/docs/marketing-api/conversions-api/get-started#--------------" }, { children: "Read more" }))] })),
        },
    ],
};
exports.default = facebookDestination;
