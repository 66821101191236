"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
var jsx_runtime_1 = require("react/jsx-runtime");
var common_1 = require("./common");
var types_1 = require("../../sources/types");
var icon = ((0, jsx_runtime_1.jsx)("svg", __assign({ xmlns: "http://www.w3.org/2000/svg", height: "100%", width: "100%", viewBox: "0 0 64 64" }, { children: (0, jsx_runtime_1.jsxs)("g", __assign({ transform: "matrix(.363638 0 0 .363636 -3.272763 -2.909091)" }, { children: [(0, jsx_runtime_1.jsx)("path", { d: "M130 29v132c0 14.77 10.2 23 21 23 10 0 21-7 21-23V30c0-13.54-10-22-21-22s-21 9.33-21 21z", fill: "#f9ab00" }), (0, jsx_runtime_1.jsxs)("g", __assign({ fill: "#e37400" }, { children: [(0, jsx_runtime_1.jsx)("path", { d: "M75 96v65c0 14.77 10.2 23 21 23 10 0 21-7 21-23V97c0-13.54-10-22-21-22s-21 9.33-21 21z" }), (0, jsx_runtime_1.jsx)("circle", { cx: "41", cy: "163", r: "21" })] }))] })) })));
var googleAnalytics = {
    description: ((0, jsx_runtime_1.jsx)(jsx_runtime_1.Fragment, { children: "Jitsu can send events from JS SDK or Events API to Google Analytics API. The common use-case is to send conversions or/and page views to Google to use that data for targeting and ad optimization" })),
    syncFromSourcesStatus: "not_supported",
    id: "google_analytics",
    type: "other",
    displayName: "GoogleAnalytics",
    defaultTransform: "// Code of Google Analytics transform:\n// https://github.com/jitsucom/jitsu/blob/master/server/storages/transform/google_analytics.js\nreturn toGoogleAnalytics($)",
    hidden: false,
    deprecated: false,
    parameters: [
        (0, common_1.modeParameter)("stream"),
        (0, common_1.tableName)(common_1.filteringExpressionDocumentation),
        {
            id: "_formData.gaTrackingId",
            displayName: "Tracking ID",
            required: true,
            type: types_1.stringType,
        },
    ],
    ui: {
        icon: icon,
        connectCmd: null,
        title: function (cfg) { return cfg["_formData"]["gaTrackingId"]; },
    },
};
exports.default = googleAnalytics;
